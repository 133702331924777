<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="color" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col cols="1"> <v-avatar :color="color" size="30" class="mr-2"> </v-avatar></v-col>
        <v-col
          ><v-text-field
            v-model="color"
            :label="label"
            :rules="rules"
            :dense="dense"
            :outlined="outlined"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field
        ></v-col>
      </v-row>
    </template>
    <v-card>
      <v-color-picker mode="hexa" v-model="color"></v-color-picker>

      <v-divider></v-divider>
      <br />
      <v-btn text color="primary" @click="modal = false"> Cancelar </v-btn>
      <v-btn text color="primary" @click="select()"> OK </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
export default {
  props: {
    value: String,
    dense: Boolean,
    outlined: Boolean,
    label: String,
    rules: Array,
  },
  setup(props, contex) {
    const modal = ref(false)
    const color = ref({ hex: null })
    const dialog = ref(null)
    onBeforeMount(() => {
      if (props.value) {
        color.value = props.value
        dialog.value.save(color.value)
      }
    })

    watch(props, () => {
      color.value = props.value
      dialog.value.save(color.value)
    })

    const select = () => {
      dialog.value.save(color.value)
      contex.emit('input', color.value)
    }
    return {
      modal,
      select,
      dialog,
      color,
    }
  },
}
</script>